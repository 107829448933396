<template>
  <el-card shadow="never">
    <div slot="header">
      <el-row>
        <el-form
            id="change"
            status-icon
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
        >
          关键词：
          <el-input
              class="form-item"
              v-model="asearch1"
              prefix-icon="el-icon-search"
              clearable
              placeholder="请输入文章名称"
          />
          产品发布人：
          <el-input
              class="form-item"
              v-model="asearch2"
              prefix-icon="el-icon-search"
              clearable
              placeholder="请输入产品发布人"
          />
          产品类型名称：
          <el-select
              v-model="productTypeId"
              placeholder="请选择"
              filterable
              class="form-item"
          >
            <el-option
                v-for="item in productOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form>
        <el-row style="margin-top: 20px">
          所属客户：
          <el-select v-model="customerId" placeholder="请选择" filterable clearable
                     class="form-item">
            <el-option
                v-for="item in customerOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button
              type="primary"
              @click="getproductList()"
              style="background: #1d90ff; border: none"
          >查询
          </el-button>
        </el-row>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-switch
            class="form-item"
            v-model="showSort"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="展开排序"
            inactive-text="关闭排序">
        </el-switch>
        <el-button
            v-show="showSort"
            type="primary"
            @click="saveSort()"
            style="background: #1d90ff; border: none"
        >保存排序
        </el-button>
      </el-row>
    </div>
    <el-table stripe :data="tableData" border :row-class-name="tableRowClassName">
      <el-table-column prop="id" label="编号" width="60" align="center">
      </el-table-column>
      <el-table-column prop="title" label="标题" width="110" align="center">
      </el-table-column>
      <el-table-column label="主图" width="130" align="center">
        <template slot-scope="scope">
          <div class="demo-image__preview">
            <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.image"
                :preview-src-list="csrcList(scope.row.image)"
            >
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="nickName"
          label="产品发布人"
          width="100"
          align="center"
      >
      </el-table-column>
      <el-table-column
          label="产品类型"
          prop="productTypeName"
          width="110"
          align="center"
      >
      </el-table-column>
      <el-table-column
          label="所属客户"
          prop="customerName"
          width="110"
          align="center"
      >
        <template slot-scope="scope">
          <el-tag v-show="scope.row.customerName" type="warning">{{ scope.row.customerName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="200" align="center">
        <template slot-scope="scope">
          <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="已审核"
              inactive-text="待审核"
              active-color="blue"
              @change="updateType(scope.row.id,scope.row.status)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          label="升序/降序"
          width="170"
          align="center"
      >
        <template slot-scope="scope">
          <el-input-number
              :disabled="!showSort"
              v-model="scope.row.sort"
              @change="updateSort(scope.row.id,scope.row.sort)"
              :min="0"
              size="mini"
              label="当前排序值"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
          prop="createdTime"
          label="开始时间"
          width="120"
          align="center"
      ></el-table-column>
      <el-table-column
          prop="updatedTime"
          label="更新时间"
          width="120"
          align="center"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button type="success" style="background: #1d90ff; border:none" size="mini"
                     @click="editArticle(scope.row.id)">编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center;margin-top: 20px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage4"
          :page-sizes="[10, 20, 50, 60]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagetotal"
      >
      </el-pagination>
    </div>
  </el-card>
</template>
<style>

.el-table .success-row {
  background: #b1b1b1;
}
</style>
<script>

export default {
  data() {
    return {
      showSort: false,
      sortMap: new Map(),
      customerOptions: [],
      customerId: null,
      id: null,
      sstatus: null,
      value1: true,
      srcList: [],
      options: [],
      productOptions: [],
      productTypeId: null,
      status: 0,
      tableData: [],
      asearch1: "",
      asearch2: "",
      asearch3: "",
      asearchdata: {},
      userInfo: {},
      updateStatus: 0,
      pagetotal: 0,
      spagetotal: "10",
      nowpagetotal: "1",
      currentPage4: 1,
    };
  },
  methods: {
    tableRowClassName({row}) {
      if (row.id === this.currentSortProId) {
        return 'success-row';
      }
      return '';
    },
    updateSort(id, sort) {
      this.sortMap.set(id, sort);
    },
    saveSort() {
      this.showSort = false
      let sortArr = new Array()
      if (this.sortMap.size !== 0) {
        this.sortMap.forEach((value, key) => {
          let sort = {}
          sort.id = key
          sort.sort = value
          sortArr.push(sort)
        })
        this.$api.post('/product/updateBatch/sort',
            null,
            sortArr,
            (successRes) => {
              this.sortMap = new Map()
              this.getproductList()
            },
            (failureRes) => {
              console.log(failureRes);
            })
      }
    },
    csrcList(image) {
      const list = [];
      list.push(image);
      list.push(image);
      return list;
    },

    updateType(id, status) {
      console.log(status);
      this.$api.post(
          "/product/update/status",
          null,
          {
            id: id,
            status: status,
          },
          (successRes) => {
            console.log(successRes);
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );

    },
    getproductList() {
      this.$api.get(
          "/product/list",
          null,
          {
            page: this.nowpagetotal,
            record: this.spagetotal,
            keyword: this.asearch1,
            nickName: this.asearch2,
            productTypeName: this.productTypeId,
            customerId: this.customerId,
            audit: true,
          },
          (successRes) => {
            var exf = successRes.data.rows;
            for (var i = 0; i < exf.length; i++) {
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
              exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
              exf[i].updatedTime = exf[i].updatedTime.substring(0, 10);
              exf[i].createdTime = exf[i].createdTime.substr(0, 19);
              exf[i].createdTime = new Date(+new Date(exf[i].createdTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
              exf[i].createdTime = exf[i].createdTime.substring(0, 10);
            }
            this.tableData = exf;
            this.pagetotal = successRes.data.total;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    getproductType() {
      this.$api.get(
          "/product/list/type",
          null,
          {
            list: false,
          },
          (successRes) => {
            this.productOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    getcustomer() {
      this.$api.get(
          "/customer/list",
          null,
          {
            list: false,
          },
          (successRes) => {
            this.customerOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    handleSizeChange(val) {
      this.spagetotal = val;
      this.getproductList()
    },
    handleCurrentChange(val) {
      this.nowpagetotal = val;
      this.getproductList()
    },
    editArticle(id) {
      this.$router.push({path: 'productauditedit', query: {id: id}});
    },
  }
  ,
  mounted() {
    this.getproductList();
    this.getproductType();
    this.getcustomer();
  }
  ,
}
;
</script>
<style scoped>
.form-item {
  width: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>